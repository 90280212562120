/**
 * @var hibob_labels
 */
(function ($) {
  const slick_a11y_labels = function (e, slick) {
    const tablist_aria_label = slick.$slider?.data('tablist-label');

    slick.$dots
      ?.attr('aria-label', tablist_aria_label ? tablist_aria_label : hibob_labels.slider_tablist_label)
      ?.find('button').each(function (i, el) {
        const tab_panel = slick.$slides?.eq(i);
        const aria_labelledby = tab_panel.attr('aria-labelledby');
        const aria_describedby = tab_panel.data('aria-describedby');

        // Set tab attributes with the same labelledby and describedby as the corresponding tab panel.
        $(el).attr({
          'aria-labelledby': aria_labelledby ? aria_labelledby : null,
          'aria-describedby': aria_describedby ? aria_describedby : null,
          'aria-label': null, // remove label because the tab has the same text inside it.
        });

        // Remove tab panel labelledby attribute.
        tab_panel.attr({
          'aria-labelledby': null,
        });
      });
  };

  $(function () {
    const homeVideo = $('#home-video');

    if (homeVideo.is(':visible')) {
      $('source', homeVideo).each(
        function () {
          const el = $(this);
          el.attr('src', el.data('src'));
        }
      );

      homeVideo[0].load();
      homeVideo.addClass('video-loaded');
    }
  });

  if( $('#main-carousel').length >= 1) {
    $('#main-carousel').slick({
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 0,
      infinite: true,
      speed: 3000,
      slidesToShow: 8,
      slidesToScroll: 1,
      centerMode: false,
      variableWidth: true,
      rtl: false,
      cssEase: 'linear',
    });
  }

  const $caseStudiesSlider = $('.case_studies_slider');
  if( $caseStudiesSlider.length >= 1) {
    $caseStudiesSlider.on('init', slick_a11y_labels).slick({
      dots: true,
      prevArrow: `<button class="slider-button slick-prev" aria-label="${hibob_labels.prev}">`,
      nextArrow: `<button class="slider-button slick-next" aria-label="${hibob_labels.next}">`,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      rtl: false,
      responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows:false
              }
            },
          ]
    });
  }

  const $testimonialsSlider = $('.testimonials_slider');
  if( $testimonialsSlider.length >= 1) {
    $testimonialsSlider.on('init', slick_a11y_labels).slick({
      dots: true,
      arrows: false,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      rtl: false,
    });
  }

}) (jQuery);
